import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import config from "../../../config";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import InputField from "components/fields/InputField";
import { BiPencil } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { FiExternalLink } from "react-icons/fi";
import { Alert, Button, TextField } from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "80vh",
  overflowY: "scroll",
};

const deleteModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: 200,
  overflowY: "scroll",
};

const SaleForce = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [storeParams, setStoreParams] = useState(null);
  const [searchText, setSearchText] = useState("");

  // State for Pharmacy Details
  const [pharmacyDetails, setPharmacyDetails] = useState({
    fullName: "",
    bussinessName: "",
    bussinessRegNo: "",
    gstNo: "",
    medicalLicenseNo: "",
    address: "",
    pincode: "",
    businessPhone: "",
    ownerPhone: "",
    email: "",
    dateOfRegistration: "",
    dateOfMedicalLicense: "",
    businessTiming: "",
    location: "",
    pharmacyId: "",
  });

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleClose = () => setOpen(false);
  const handleDeleteClose = () => setDeleteOpen(false);
  const handleDeleteOpen = (params) => {
    setDeleteOpen(true);
    setStoreParams(params);
  };

  useEffect(() => {
    getAllPharmacies();
  }, []);

  const getAllPharmacies = () => {
    axios
      .get(`https://api.medstown.com/pharmacy/getpharmacy`)
      .then((res) => {
        console.log(res.data.pharmacies)
        setData(res.data.pharmacies || []);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleEditPharmacy = (row) => {
    setOpen(true);
    setPharmacyDetails({
      ...pharmacyDetails,
      ...row.row
    });
  };

  const handleSubmitDetails = () => {
    axios
      .post(`${config.api}/editpharmacy`, pharmacyDetails)
      .then((res) => {
        getAllPharmacies();
        handleClose();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeletePharmacy = (data) => {
    axios
      .post(`${config.api}/deletepharmacy`, { pharmacyId: data.row.pharmacyId })
      .then((res) => {
        getAllPharmacies();
        handleDeleteClose();
      })
      .catch((err) => {
        console.error(err);
        handleDeleteClose();
      });
  };

  const filteredRows = data.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchText}
          onChange={handleSearchTextChange}
        />

        <DataGrid
          rows={filteredRows}
          columns={[
            { field: "bussinessName", headerName: "Name", width: 200 },
            { field: "businessPhone", headerName: "Phone", width: 200 },
            { field: "fullName", headerName: "Owner Name", width: 200 },
            { field: "address", headerName: "Address", width: 200 },
            { field: "ownerPhone", headerName: "Owner Phone", width: 200 },
            { field: "email", headerName: "Email", width: 200 },
            {
              field: "dateOfRegistration",
              headerName: "Date Of Registration",
              width: 200,
            },
            {
              field: "dateOfMedicalLicense",
              headerName: "Date Of Medical License",
              width: 200,
            },
            {
              field: "bussinessRegNo",
              headerName: "Bussiness Reg No",
              width: 200,
            },
            { field: "gstNo", headerName: "GST No", width: 200 },
            {
              field: "medicalLicenseNo",
              headerName: "Medical License No",
              width: 200,
            },
            { field: "pincode", headerName: "Pincode", width: 200 },
            {
              field: "businessTiming",
              headerName: "Business Timing",
              width: 200,
              renderCell: (params) => (
                <div className="flex items-center">
                  {params.row.businessTiming.map((item, index) => (
                    <div key={index} className="flex flex-col">
                      <span>Start: {item.start} AM</span>
                      <span>End: {item.end} PM</span>
                    </div>
                  ))}
                </div>
              ),
            },
            {
              field: "location",
              headerName: "Location",
              width: 200,
              renderCell: (params) => {
                const [latitude, longitude] = params.row.location.coordinates || [];
                console.log(params.row)
                return (
                  <div className="flex items-center">
                    <FiExternalLink
                      size={24}
                      className="cursor-pointer"
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
                        )
                      }
                    />
                  </div>
                );
              },
            },
            {
              field: "Action",
              headerName: "Action",
              width: 200,
              renderCell: (params) => (
                <div className="flex items-center">
                  <BiPencil
                    size={18}
                    onClick={() => handleEditPharmacy(params)}
                    className="mr-7 cursor-pointer hover:text-blue-500"
                  />
                  <AiFillDelete
                    size={18}
                    onClick={() => handleDeleteOpen(params)}
                    className="mr-7 cursor-pointer hover:text-red-500"
                  />
                </div>
              ),
            },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          sx={{ width: "100%" }}
          getRowId={(row) => row._id}
          components={{
            Toolbar: GridToolbar,
          }}
          onRowClick={(row) => handleEditPharmacy(row)}
        />
      </div>

      {/* Edit Pharmacy Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="sticky -top-10 z-50 mb-5 flex h-14 items-center justify-between bg-white px-1">
            <h4 className="text-2xl font-bold">Edit Pharmacy</h4>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <InputField
              label="Full Name"
              value={pharmacyDetails.fullName}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, fullName: e.target.value })}
            />
            <InputField
              label="Bussiness Name"
              value={pharmacyDetails.bussinessName}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, bussinessName: e.target.value })}
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <InputField
              label="Bussiness Reg No"
              value={pharmacyDetails.bussinessRegNo}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, bussinessRegNo: e.target.value })}
            />
            <InputField
              label="GST No"
              value={pharmacyDetails.gstNo}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, gstNo: e.target.value })}
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <InputField
              label="Medical License No"
              value={pharmacyDetails.medicalLicenseNo}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, medicalLicenseNo: e.target.value })}
            />
            <InputField
              label="Address"
              value={pharmacyDetails.address}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, address: e.target.value })}
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <InputField
              label="Pincode"
              value={pharmacyDetails.pincode}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, pincode: e.target.value })}
            />
            <InputField
              label="Business Phone"
              value={pharmacyDetails.businessPhone}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, businessPhone: e.target.value })}
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <InputField
              label="Owner Phone"
              value={pharmacyDetails.ownerPhone}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, ownerPhone: e.target.value })}
            />
            <InputField
              label="Email"
              value={pharmacyDetails.email}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, email: e.target.value })}
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <InputField
              label="Date Of Registration"
              value={pharmacyDetails.dateOfRegistration}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, dateOfRegistration: e.target.value })}
            />
            <InputField
              label="Date Of Medical License"
              value={pharmacyDetails.dateOfMedicalLicense}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, dateOfMedicalLicense: e.target.value })}
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <InputField
              label="Business Timing"
              value={pharmacyDetails.businessTiming}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, businessTiming: e.target.value })}
            />
            <InputField
              label="Location"
              value={pharmacyDetails.location}
              onChange={(e) => setPharmacyDetails({ ...pharmacyDetails, location: e.target.value })}
            />
          </div>
          <Button
            variant="contained"
            onClick={handleSubmitDetails}
            sx={{ marginTop: 2 }}
          >
            Save Changes
          </Button>
        </Box>
      </Modal>

      {/* Delete Pharmacy Modal */}
      <Modal
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteModalStyle}>
          <Alert severity="warning">
            Are you sure you want to delete this pharmacy?
          </Alert>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeletePharmacy(storeParams)}
            sx={{ marginTop: 2 }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteClose}
            sx={{ marginTop: 2, marginLeft: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default SaleForce;
