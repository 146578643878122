import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
} from "@mui/material";
import { MyContext } from "Contextapi/MyContext";
import MyLocation from "Contextapi/MyLocation";
import { styled } from "@mui/system";
import { BugReportTwoTone } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const TimelineContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  overflowX: "auto",
  width: "100%",
  padding: "16px",
  whiteSpace: "nowrap",
  position: "relative",
}));

const TimelineItem = styled(Box)(({ theme, isLast }) => ({
  display: "inline-block",
  position: "relative",
  textAlign: "center",
  width: "200px",
  "&:not(:last-child)": {
    marginRight: "32px",
  },
}));

const TimelineDot = styled(Box)(({ theme, hasData }) => ({
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: hasData ? "#004d4d" : "#d3d3d3",
  border: `2px solid ${hasData ? "#004d4d" : "#d3d3d3"}`,
  marginBottom: "8px",
  position: "relative",
  zIndex: 1,
}));

const TimelineLine = styled(Box)(({ theme, hasData, isLast }) => ({
  position: "absolute",
  left: 50,
  bottom: 9,
  width: "5px", // Width of the vertical line
  height: "calc(100% + 16px)", // Extend beyond the timeline item
  backgroundColor: hasData ? "#004d4d" : "#d3d3d3",
  transform: "rotate(90deg) ", // Center the line horizontally

  zIndex: 0,
  visibility: isLast ? "hidden" : "visible", // Hide the line for the last item
}));
const TimelineLine2 = styled(Box)(({ theme, hasData, isLast }) => ({
  position: "absolute",
  left: 100,
  bottom: 9,
  width: "5px", // Width of the vertical line
  height: "calc(100% + 16px)", // Extend beyond the timeline item
  backgroundColor: hasData ? "#004d4d" : "#d3d3d3",
  transform: "rotate(90deg) ", // Center the line horizontally

  zIndex: 0,
  visibility: isLast ? "hidden" : "visible", // Hide the line for the last item
}));
const TimelineLine3 = styled(Box)(({ theme, hasData, isLast }) => ({
  position: "absolute",
  left: 150,
  bottom: 9,
  width: "5px", // Width of the vertical line
  height: "calc(100% + 16px)", // Extend beyond the timeline item
  backgroundColor: hasData ? "#004d4d" : "#d3d3d3",
  transform: "rotate(90deg) ", // Center the line horizontally

  zIndex: 0,
  visibility: isLast ? "hidden" : "visible", // Hide the line for the last item
}));
const TimelineLine4 = styled(Box)(({ theme, hasData, isLast }) => ({
  position: "absolute",
  left: 200,
  bottom: 9,
  width: "5px", // Width of the vertical line
  height: "calc(100% + 16px)", // Extend beyond the timeline item
  backgroundColor: hasData ? "#004d4d" : "#d3d3d3",
  transform: "rotate(90deg) ", // Center the line horizontally

  zIndex: 0,
  visibility: isLast ? "hidden" : "visible", // Hide the line for the last item
}));

const DetailBox = styled(Box)(({ theme }) => ({
  padding: "16px",
  backgroundColor: "#f5f5f5",
  borderRadius: "8px",
}));

const OrderHistoryPage = () => {
  const [allOrders, setOrderHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedStep, setSelectedStep] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [cancelres, setCancelres] = useState();

  useEffect(() => {
    getAllOrders();
    airorder()
  }, [cancelres]);

  const getAllOrders = () => {
    setLoading(true);
    axios
      .get("https://api.medstown.com/customer/finalorder")
      .then((res) => {
        setLoading(false);
        setOrderHistory(res.data.reverse());
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const airorder = async () => {
    setLoading(true)
    const res = await axios.get("https://api.medstown.com/customer/getorders")
    console.log(res.data , "Ssss")
 setOrderHistory(res.data.reverse());
  }

  const handleRowClick = (row) => {
    setCurrentRow(row);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDotClick = (step) => {
    setSelectedStep(step);
    setOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const cenaclorderhendler = async (id) => {
    setLoading(true);
    const data = {
      orderId: id,
    };
    try {
      console.log("Sending data:", data);
      const res = await axios.post(
        `https://api.medstown.com/customer/finalorder/status`,
        data
      );
      console.log("Response data:", res.data.message);
      setCancelres(res.data.message);
      alert(res.data.message);
      setLoading(false);
    } catch (error) {
      console.error(
        "Error cancelling order:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false); // Ensure loading state is turned off after the request
    }
  };


  const allorder = async () => {
    const res = await axios.get(`https://api.medstown.com/customer/getorders`)
    console.log(res.data , "Dtaa")
    setOrderHistory(res.data.reverse());
  }

  

  const { dataorder } = useContext(MyContext);

  const tableColumns = [
    { id: "orderId", label: "Order Id", minWidth: 150 },
    { id: "Customer Name", label: "customerName", minWidth: 150 },
    { id: "Order status", label: "Status", minWidth: 170 },
    { id: "otpValue", label: "Delivery Code", minWidth: 170 },
    { id: "totalPrice", label: "Total Price", minWidth: 170 },
    { id: "paymentType", label: "Payment Type", minWidth: 170 },
    { id: "createdAt", label: "Time", minWidth: 250 },
    { id: "createdAt", label: "Date", minWidth: 250 },
    
  ];
  const isCancelled = currentRow?.status === "Order Cancelled";







  return (
    <div className="mt-8">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="order table">
          <TableHead>
            <TableRow>
              {tableColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>

            {allOrders?.map((row) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.orderId}
                onClick={() => handleRowClick(row)}
              >
                <TableCell align="left">{row.orderId}</TableCell>
                <TableCell align="left">{row.customerName}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.otpValue}</TableCell>
                <TableCell align="left">{row.totalPrice}</TableCell>
                <TableCell align="left">{row.paymentType}</TableCell>
                <TableCell align="left">
                  {new Date(row.createdAt).toDateString()}
                </TableCell>
                <TableCell align="left">
                  {new Date(row.createdAt).toLocaleTimeString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="mt-4 flex items-center justify-end gap-5">
        <p className="font-semibold">Page No: {loading ? "_" : "1"}</p>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={getAllOrders}
        >
          Refresh
        </Button>
      </Box>

      {/* Modal for Order Details */}
      <Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Order Details</DialogTitle>
        <DialogContent>
          {currentRow ? (
            <>
              <Typography variant="h6" gutterBottom>
                Order ID: {currentRow?.orderId}
              </Typography>
              <Typography variant="body1">
                User Location:{" "}
                <MyLocation
                  latitude={currentRow?.userLat}
                  longitude={currentRow?.userLng}
                />
              </Typography>
              <Typography variant="body1">
                Pharmacy Location:
                <MyLocation
                  latitude={currentRow?.pharmacyLng}
                  longitude={currentRow?.pharmacyLat}
                />
              </Typography>
              <Typography variant="body1">
                Status: {currentRow?.status}
              </Typography>
              <Typography variant="body1">
                Delivery Code: {currentRow?.otpValue}
              </Typography>
              <Typography variant="body1">
                Total Price: {currentRow?.totalPrice}
              </Typography>
              <Typography variant="body1">
                Payment Type: {currentRow?.paymentType}
              </Typography>
              <Typography variant="body1">
                Date: {new Date(currentRow?.createdAt).toDateString()}
              </Typography>
              <Typography variant="body1">
                Time: {new Date(currentRow?.createdAt).toLocaleTimeString()}
              </Typography>
              <Divider sx={{ my: 2 }} />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                margin="30px"
              >
                <Typography variant="h6">Order Timeline</Typography>


                
                <Button
                  variant="contained"
                  onClick={() => cenaclorderhendler(currentRow?.orderId)}
                  disabled={loading} // Assume `loading` is managed in the component's state
                >
                  {loading ? "Cancelling..." : "Cancel Order"}
                </Button>
              </Box>



              <TimelineContainer>
                {/* Timeline Steps */}
                <TimelineItem isLast={false}>
                  {/* <CheckCircleIcon/> */}

                  <TimelineDot
                    onClick={() => handleDotClick("user")}
                    hasData={
                      !isCancelled && currentRow?.userLat && currentRow?.userLng
                    }
                  />
                  <TimelineLine
                    hasData={
                      !isCancelled && currentRow?.userLat && currentRow?.userLng
                    }
                    isLast={false}
                  />
                  <TimelineLine2
                    hasData={
                      !isCancelled && currentRow?.userLat && currentRow?.userLng
                    }
                    isLast={false}
                  />
                  <TimelineLine3
                    hasData={
                      !isCancelled && currentRow?.userLat && currentRow?.userLng
                    }
                    isLast={false}
                  />
                  <TimelineLine4
                    hasData={
                      !isCancelled && currentRow?.userLat && currentRow?.userLng
                    }
                    isLast={false}
                  />

                  <Box sx={{ marginTop: "8px", textAlign: "center" }}>
                    <Typography>User Details</Typography>
                  </Box>
                </TimelineItem>
                <TimelineItem isLast={false}>
                  <TimelineDot
                    hasData={!isCancelled && currentRow?.pharmacyLat}
                    onClick={() => handleDotClick("pharmacy")}
                  />
                  <TimelineLine
                    hasData={!isCancelled && currentRow?.pharmacyLat}
                    isLast={false}
                  />
                  <TimelineLine2
                    hasData={!isCancelled && currentRow?.pharmacyLat}
                    isLast={false}
                  />
                  <TimelineLine3
                    hasData={!isCancelled && currentRow?.pharmacyLat}
                    isLast={false}
                  />
                  <TimelineLine4
                    hasData={!isCancelled && currentRow?.pharmacyLat}
                    isLast={false}
                  />
                  <Box sx={{ marginTop: "8px", textAlign: "center" }}>
                    <Typography>Pharmacy Details</Typography>
                  </Box>
                </TimelineItem>
                <TimelineItem isLast={false}>
                  <TimelineDot
                    hasData={!isCancelled && currentRow?.deliveryBoyLat}
                    onClick={() => handleDotClick("delivery")}
                  />
                  <TimelineLine
                    hasData={!isCancelled && currentRow?.deliveryBoyLat}
                    isLast={false}
                  />
                  <TimelineLine2
                    hasData={!isCancelled && currentRow?.deliveryBoyLat}
                    isLast={false}
                  />
                  <TimelineLine3
                    hasData={!isCancelled && currentRow?.deliveryBoyLat}
                    isLast={false}
                  />
                  <TimelineLine4
                    hasData={!isCancelled && currentRow?.deliveryBoyLat}
                    isLast={false}
                  />
                  <Box sx={{ marginTop: "8px", textAlign: "center" }}>
                    <Typography>Delivery Details</Typography>
                  </Box>
                </TimelineItem>
                <TimelineItem isLast={true}>
                  <TimelineDot
                    hasData={currentRow?.status === "Delivered"}
                    onClick={() => handleDotClick("status")}
                  />
                  <Box sx={{ marginTop: "8px", textAlign: "center" }}>
                    <Typography>Status:{currentRow?.status}</Typography>
                  </Box>
                </TimelineItem>
              </TimelineContainer>

              {/* DetailBox */}
              <Dialog
                open={openDetail}
                onClose={handleCloseDetail}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle>Details</DialogTitle>
                <DialogContent>
                  <DetailBox>
                    {selectedStep === "user" && (
                      <>
                        <Typography variant="h6">User Details</Typography>

                        
                        <Typography variant="body1">
                          Name: {currentRow?.customerName}
                        </Typography>
                        <Typography variant="body1">
                          Phone: {currentRow?.customerPhone}
                        </Typography>
                        <Typography variant="body1">
                          Location:{" "}
                          <MyLocation
                            latitude={currentRow?.userLat}
                            longitude={currentRow?.userLng}
                          />
                        </Typography>
                      </>
                    )}
                    {selectedStep === "pharmacy" && (
                      <>
                        <Typography variant="h6">Pharmacy Details</Typography>
                       
                        <Typography variant="body1">
                          Name: {currentRow?.pharmacyName}
                        </Typography>
                        <Typography variant="body1">
                          Phone: {currentRow?.pharmacyPhone}
                        </Typography>
                        <Typography variant="body1">
                          Location:{" "}
                          <MyLocation
                            latitude={currentRow?.pharmacyLng}
                            longitude={currentRow?.pharmacyLat}
                          />
                        </Typography>
                      </>
                    )}
                    {selectedStep === "delivery" && (
                      <>
                        <Typography variant="h6">Delivery Details</Typography>
                       
                        <Typography variant="body1">
                          Name: {currentRow?.deliveryBoyName}
                        </Typography>
                        <Typography variant="body1">
                          Phone: {currentRow?.deliveryBoyPhone}
                        </Typography>
                        <Typography variant="body1">
                          Location:{" "}
                          <MyLocation
                            latitude={currentRow?.deliveryBoyLat}
                            longitude={currentRow?.deliveryBoyLng}
                          />
                        </Typography>
                      </>
                    )}
                    {selectedStep === "status" && (
                      <>
                        <Typography variant="h6">Order Status</Typography>
                        <Typography variant="body1">
                          Date: {new Date(currentRow?.createdAt).toDateString()}
                        </Typography>
                        <Typography variant="body1">
                          Time:{" "}
                          {new Date(currentRow?.createdAt).toLocaleTimeString()}
                        </Typography>
                        <Typography variant="body1">
                          Status: {currentRow?.status}
                        </Typography>
                      </>
                    )}
                  </DetailBox>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDetail} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : (
            <Typography>No details available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderHistoryPage;
