// import React, { useEffect, useState } from "react";
// import { DataGrid } from "@mui/x-data-grid";
// import axios from "axios";
// import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";
// import InputField from "components/fields/InputField";
// import { TextField, Card, CardContent, Button, CardActions } from "@mui/material";
// import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 1000,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
//   height: "80vh",
//   overflowY: "scroll",
// };

// const modalBoxStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

// const Delivery = () => {
//   const [data, setData] = useState([]);
//   const [open, setOpen] = useState(false);
//   const [searchText, setSearchText] = useState("");
//   const [currentRow, setCurrentRow] = useState(null);
//   const [openModal, setOpenModal] = useState(false);
//   const [deliveryPersonDetails, setDeliveryPersonDetails] = useState(null);
//   const [updated, setUpdated] = useState({ type: '', message: '', open: false });
  
//   // State variables for the pharmacy details modal
//   const [fullName, setFullName] = useState("");
//   const [bussinessName, setBussinessName] = useState("");
//   const [bussinessRegNo, setBussinessRegNo] = useState("");
//   const [gstNo, setGstNo] = useState("");
//   const [medicalLicenseNo, setMedicalLicenseNo] = useState("");
//   const [address, setAddress] = useState("");
//   const [pincode, setPincode] = useState("");
//   const [businessPhone, setBusinessPhone] = useState("");
//   const [ownerPhone, setOwnerPhone] = useState("");
//   const [email, setEmail] = useState("");
//   const [dateOfRegistration, setDateOfRegistration] = useState("");
//   const [dateOfMedicalLicense, setDateOfMedicalLicense] = useState("");
  
//   // State for the map marker
//   const [showMarker, setShowMarker] = useState(null);

//   useEffect(() => {
//     getAllDeliveryBoys();
//   }, []);

//   const getAllDeliveryBoys = async () => {
//     try {
//       const response = await axios.get("https://api.medstown.com/delivery/getalldeliveryboy");
//       setData(response.data.reverse());
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleRowClick = (row) => {
//     setCurrentRow(row);
//     setOpenModal(true);
//     setDeliveryPersonDetails(row.row);
//   };

//   const updateDetailsHandler = async () => {
//     const id = deliveryPersonDetails._id;
//     const config = {
//       headers: {
//         "Content-Type": "application/json"
//       }
//     };

//     const body = {
//       fullname: deliveryPersonDetails.fullname,
//       phone: deliveryPersonDetails.phone,
//       vehicleNumber: deliveryPersonDetails.vehicleNumber,
//       drivingLicense: deliveryPersonDetails.drivingLicense
//     };

//     try {
//       const response = await axios.put(`https://api.medstown.com/delivery/updateDetails/${id}`, body, config);
//       if (response.data.status === "success") {
//         setUpdated({ type: "update", message: "Details updated", open: true });
//         setTimeout(() => setUpdated({ open: false }), 3000);
//         getAllDeliveryBoys();
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const deleteDetailsHandler = async () => {
//     const id = deliveryPersonDetails._id;

//     try {
//       const response = await axios.delete(`https://api.medstown.com/delivery/deleteDetails/${id}`);
//       if (response.data.status === "success") {
//         setUpdated({ type: "deleted", message: "Details deleted", open: true });
//         setTimeout(() => setUpdated({ open: false }), 2000);
//         setOpenModal(false);
//         getAllDeliveryBoys();
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleButtonClick = (row) => {
//     // Handle the button click logic here
//     console.log('Button clicked for row:', row);
//   };

  // const MyMapComponent = withScriptjs(withGoogleMap((props) => (
  //   <GoogleMap defaultZoom={11} defaultCenter={{ lat: 17.4374837, lng: 78.5158477 }}>
  //     {props.data && props.data.map((item) => (
  //       <React.Fragment key={item._id}>
  //         <Marker
  //           position={{ lat: item.location?.coordinates[0], lng: item.location?.coordinates[1] }}
  //           icon={{
  //             url: "https://usc1.contabostorage.com/f49065475849480fbcd19fb8279b2f98:medstowninternal/delbike.png",
  //             scaledSize: new window.google.maps.Size(50, 50),
  //           }}
  //           onClick={() => setShowMarker(item._id)}
  //         />
  //         {showMarker === item._id &&
  //           <InfoWindow
  //             position={{ lat: item.location.coordinates[0], lng: item.location.coordinates[1] }}
  //             onCloseClick={() => setShowMarker(null)}
  //           >
  //             <div>
  //               <h2>{item.fullname}</h2>
  //               <p>{item.phone}</p>
  //             </div>
  //           </InfoWindow>
  //         }
  //       </React.Fragment>
  //     ))}
  //   </GoogleMap>
  // )));

//   const handleSearchTextChange = (event) => setSearchText(event.target.value);
//   const filteredRows = data.filter((row) =>
//     Object.values(row).some(
//       (value) => value && value.toString().toLowerCase().includes(searchText.toLowerCase())
//     )
//   );

//   return (
//     <div>
//       <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        // <MyMapComponent
        //   googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC8z91LrDu6klUBb9BFBG3Zd_v_3kjVTBI&v=3.exp&libraries=geometry,drawing,places"
        //   loadingElement={<div style={{ height: `100%` }} />}
        //   containerElement={<div style={{ height: `300px`, width: "100%" }} />}
        //   mapElement={<div style={{ height: `100%` }} />}
        //   data={data}
        // />
//       </div>

//       <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
//         <TextField
//           label="Search"
//           variant="outlined"
//           fullWidth
//           value={searchText}
//           onChange={handleSearchTextChange}
//         />
//         <DataGrid
//           rows={filteredRows}
//           columns={[
//             { field: "fullname", headerName: "Name", width: 200 },
//             { field: "phone", headerName: "Phone", width: 200 },
//             { field: "vehicleNumber", headerName: "Vehicle Number", width: 200 },
//             { field: "drivingLicense", headerName: "Driving License", width: 200 },
//             {
//               field: "location",
//               headerName: "Location",
//               width: 200,
//               renderCell: (params) => (
//                 <Button onClick={() => handleButtonClick(params.row)}>View Location</Button>
//               ),
//             }
//           ]}
//           pageSize={5}
//           rowsPerPageOptions={[5]}
//           disableSelectionOnClick
//           sx={{ width: "100%" }}
//           getRowId={(row) => row._id}
//           onRowClick={handleRowClick}
//         />
//       </div>

//       {/* Edit or Delete Delivery Boy Modal */}
//       <Modal
//         open={openModal}
//         onClose={() => setOpenModal(false)}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box style={modalBoxStyle}>
//           <Card>
//             <CardContent>
//               {updated.open && (
//                 <div style={{ marginVertical: 20 }}>
//                   <p style={{ textAlign: "center", color: "green", fontSize: 18 }}>
//                     {updated.message}
//                   </p>
//                 </div>
//               )}
//               {deliveryPersonDetails && (
//                 <>
//                   <TextField
//                     label="Name"
//                     value={deliveryPersonDetails.fullname}
//                     className="mt-5"
//                     style={{ marginTop: 15 }}
//                     fullWidth
//                     onChange={(e) => setDeliveryPersonDetails((current) => ({
//                       ...current,
//                       fullname: e.target.value
//                     }))}
//                   />
//                   <TextField
//                     label="Phone"
//                     className="mt-5"
//                     style={{ marginTop: 15 }}
//                     value={deliveryPersonDetails.phone}
//                     fullWidth
//                     onChange={(e) => setDeliveryPersonDetails((current) => ({
//                       ...current,
//                       phone: e.target.value
//                     }))}
//                   />
//                   <TextField
//                     label="Vehicle Number"
//                     className="mt-5"
//                     value={deliveryPersonDetails.vehicleNumber}
//                     fullWidth
//                     style={{ marginTop: 15 }}
//                     onChange={(e) => setDeliveryPersonDetails((current) => ({
//                       ...current,
//                       vehicleNumber: e.target.value
//                     }))}
//                   />
//                   <TextField
//                     label="Driving License"
//                     className="mt-5"
//                     style={{ marginTop: 15 }}
//                     value={deliveryPersonDetails.drivingLicense}
//                     fullWidth
//                     onChange={(e) => setDeliveryPersonDetails((current) => ({
//                       ...current,
//                       drivingLicense: e.target.value
//                     }))}
//                   />
//                 </>
//               )}
//             </CardContent>
//             <CardActions style={{ display: "flex", justifyContent: "flex-end", marginVertical: 30 }}>
//               <Button variant="contained" color="info" onClick={updateDetailsHandler}>Update Details</Button>
//               <Button variant="contained" color="error" onClick={deleteDetailsHandler}>Delete Details</Button>
//             </CardActions>
//           </Card>
//         </Box>
//       </Modal>

//       {/* Additional Modal for Pharmacy Details (if needed) */}
//       <Modal
//         open={open}
//         onClose={() => setOpen(false)}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box style={modalBoxStyle}>
//           {/* Create input fields */}
//           <div className="sticky -top-10 z-50 mb-5 flex h-14 items-center justify-between bg-white px-1">
//             <h4 className="text-2xl font-bold">Edit Pharmacy</h4>
//           </div>
//           <div className="grid grid-cols-2 gap-5">
//             <InputField label="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
//             <InputField label="Business Name" value={bussinessName} onChange={(e) => setBussinessName(e.target.value)} />
//           </div>
//           <div className="grid grid-cols-2 gap-5">
//             <InputField label="Business Reg No" value={bussinessRegNo} onChange={(e) => setBussinessRegNo(e.target.value)} />
//             <InputField label="GST No" value={gstNo} onChange={(e) => setGstNo(e.target.value)} />
//           </div>
//           <div className="grid grid-cols-2 gap-5">
//             <InputField label="Medical License No" value={medicalLicenseNo} onChange={(e) => setMedicalLicenseNo(e.target.value)} />
//             <InputField label="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
//           </div>
//           <div className="grid grid-cols-2 gap-5">
//             <InputField label="Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
//             <InputField label="Business Phone" value={businessPhone} onChange={(e) => setBusinessPhone(e.target.value)} />
//           </div>
//           <div className="grid grid-cols-2 gap-5">
//             <InputField label="Owner Phone" value={ownerPhone} onChange={(e) => setOwnerPhone(e.target.value)} />
//             <InputField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
//           </div>
//           <div className="grid grid-cols-2 gap-5">
//             <InputField label="Date Of Registration" value={dateOfRegistration} onChange={(e) => setDateOfRegistration(e.target.value)} />
//             <InputField label="Date Of Medical License" value={dateOfMedicalLicense} onChange={(e) => setDateOfMedicalLicense(e.target.value)} />
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// };

// export default Delivery;







import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import InputField from "components/fields/InputField";
import { BiPencil } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { TextField, Button, Alert } from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "80vh",
  overflowY: "scroll",
};

const deleteModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: 200,
  overflowY: "scroll",
};

const DeliveryDetails = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [storeParams, setStoreParams] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [showMarker, setShowMarker] = useState(null);

  // State for Delivery Partner Details
  const [deliveryDetails, setDeliveryDetails] = useState({
    partnerId: "",
    fullname: "",
    phone: "",
    vehicleNumber: "",
    drivingLicense: "",
    address: "",
  });

  useEffect(() => {
    getAllDeliveryPartners();
  }, []);

  const getAllDeliveryPartners = () => {
    axios
      .get(`https://api.medstown.com/delivery/getalldeliveryboy`)
      .then((res) => {
        // Add _id as id for DataGrid
        const rows = res.data.map(item => ({
          ...item,
          id: item._id
        }));
        setData(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleClose = () => setOpen(false);
  const handleDeleteClose = () => setDeleteOpen(false);
  const handleDeleteOpen = (partnerId) => {
    setDeleteOpen(true);
    setStoreParams(partnerId);
  };

  const handleEditDeliveryPartner = (row) => {
    setOpen(true);
    setDeliveryDetails({
      partnerId: row.partnerId,
      fullname: row.fullname,
      phone: row.phone,
      vehicleNumber: row.vehicleNumber,
      drivingLicense: row.drivingLicense,
      address: row.address,
    });
  };

  console.log(deliveryDetails)

  const handleSubmitDetails = () => {
    
    axios.put
      (
        `https://api.medstown.com/delivery/updatedeliveryuser/${deliveryDetails.partnerId}`,
        deliveryDetails
      )
      .then(() => {
        console.log("gg")
        getAllDeliveryPartners();
        handleClose();
      })
      .catch((err) => {
        console.error(err);

      });
  };

  const handleDeleteDeliveryPartner = (partnerId) => {
    axios
      .get(`https://api.medstown.com/delivery/deletedelvaryuser/${partnerId}`)
      .then(() => {
        getAllDeliveryPartners();
        handleDeleteClose();
      })
      .catch((err) => {
        console.error(err);
        handleDeleteClose();
      });
  };




  const MyMapComponent = withScriptjs(withGoogleMap((props) => (
    <GoogleMap defaultZoom={11} defaultCenter={{ lat: 17.4374837, lng: 78.5158477 }}>
      {props.data && props.data.map((item) => (
        <React.Fragment key={item._id}>
          <Marker
            position={{ lat: item.location?.coordinates[0], lng: item.location?.coordinates[1] }}
            icon={{
              url: "https://usc1.contabostorage.com/f49065475849480fbcd19fb8279b2f98:medstowninternal/delbike.png",
              scaledSize: new window.google.maps.Size(50, 50),
            }}
            onClick={() => setShowMarker(item._id)}
          />
          {showMarker === item._id &&
            <InfoWindow
              position={{ lat: item.location.coordinates[0], lng: item.location.coordinates[1] }}
              onCloseClick={() => setShowMarker(null)}
            >
              <div>
                <h2>{item.fullname}</h2>
                <p>{item.phone}</p>
              </div>
            </InfoWindow>
          }
        </React.Fragment>
      ))}
    </GoogleMap>
  )));










  const filteredRows = data.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">

      <MyMapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC8z91LrDu6klUBb9BFBG3Zd_v_3kjVTBI&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `300px`, width: "100%" }} />}
          mapElement={<div style={{ height: `100%` }} />}
          data={data}
        />

        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchText}
          onChange={handleSearchTextChange}
        />

        <DataGrid
          rows={filteredRows}
          columns={[
            { field: "partnerId", headerName: "ID", width: 200 },
            { field: "fullname", headerName: "Name", width: 200 },
            { field: "phone", headerName: "Contact", width: 150 },
            {
              field: "vehicleNumber",
              headerName: "Vehicle Number",
              width: 150,
            },
            {
              field: "drivingLicense",
              headerName: "Driving License No.",
              width: 250,
            },
            {
              field: "Action",
              headerName: "Action",
              width: 200,
              renderCell: (params) => (
                <div className="flex items-center">
                  <BiPencil
                    size={18}
                    onClick={() => handleEditDeliveryPartner(params.row)}
                    className="mr-7 cursor-pointer hover:text-blue-500"
                  />
                  <AiFillDelete
                    size={18}
                    onClick={() => handleDeleteOpen(params.row.partnerId)}
                    className="mr-7 cursor-pointer hover:text-red-500"
                  />
                </div>
              ),
            },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          sx={{ width: "100%" }}
          getRowId={(row) => row.id} // Ensure this matches the id field you set
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </div>

      {/* Edit Delivery Partner Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="sticky -top-10 z-50 mb-5 flex h-14 items-center justify-between bg-white px-1">
            <h4 className="text-2xl font-bold">Edit Delivery Partners</h4>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <InputField
              label="PartnerId"
              value={deliveryDetails.partnerId}
              onChange={(e) =>
                setDeliveryDetails({
                  ...deliveryDetails,
                  partnerId: e.target.value,
                })
              }
            />
            <InputField
              label="Full Name"
              value={deliveryDetails.fullname}
              onChange={(e) =>
                setDeliveryDetails({
                  ...deliveryDetails,
                  fullname: e.target.value,
                })
              }
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <InputField
              label="Phone No."
              value={deliveryDetails.phone}
              onChange={(e) =>
                setDeliveryDetails({
                  ...deliveryDetails,
                  phone: e.target.value,
                })
              }
            />
            <InputField
              label="Vehicle Number"
              value={deliveryDetails.vehicleNumber}
              onChange={(e) =>
                setDeliveryDetails({
                  ...deliveryDetails,
                  vehicleNumber: e.target.value,
                })
              }
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <InputField
              label="Driving License No"
              value={deliveryDetails.drivingLicense}
              onChange={(e) =>
                setDeliveryDetails({
                  ...deliveryDetails,
                  drivingLicense: e.target.value,
                })
              }
            />
            <InputField
              label="Address"
              value={deliveryDetails.address}
              onChange={(e) =>
                setDeliveryDetails({
                  ...deliveryDetails,
                  address: e.target.value,
                })
              }
            />
          </div>
          <Box className="flex justify-end">
            <Button
              onClick={handleSubmitDetails}
              variant="contained"
              color="primary"
              className="mr-2"
            >
              Save
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete Delivery Partner Modal */}
      <Modal
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteModalStyle}>
          <h4 className="text-2xl font-bold">Delete Delivery Partner</h4>
          <p>Are you sure you want to delete this delivery partner?</p>
          <Box className="flex justify-end mt-4">
            <Button
              onClick={() => handleDeleteDeliveryPartner(storeParams)}
              variant="contained"
              color="error"
              className="mr-2"
            >
              Delete
            </Button>
            <Button onClick={handleDeleteClose} variant="outlined">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DeliveryDetails;

